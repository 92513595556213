//jquery.validate.min.jsカスタマイズ用
//https://jqueryvalidation.org/documentation/
//https://www.htmlhifive.com/conts/web/view/library/jquery-validate
//https://dabohaze.site/validate-js-how-to/
//https://into-the-program.com/jquery-validation-plugin/
$(function () {
  if ($(".validationform").length) {
    // ふりがなのバリデーションルールを追加
    $.validator.addMethod("kana", function(value, element) {
      return this.optional(element) || /^[ぁ-んー　]*$/.test(value);
    }, "ふりがなはひらがなで入力してください");

    //フォーム指定
    $(".validationform").validate({
      //検証ルール設定
      rules: {
        //ここに検証ルールを設定(name属性を指定)
        seimei: {
          required: true,
        },
        furigana: {
          required: true,
          kana: true, // カスタムルールを適用
        },
        email: {
          required: true,
          email: true,
        }/*,
        chkemail: {
          required: true,
          email: true,
          equalTo: "input[name=mail]",
        }*/,
        comment: {
          required: true,
        },
      },

      //エラーメッセージ設定
      messages: {
        //ここにエラーメッセージを設定
        seimei: {
          required: "氏名を入力してください",
        },
        furigana: {
          required: "氏名ふりがなを入力してください",
          kana: "ふりがなはひらがなで入力してください",
        },
        email: {
          required: "メールアドレスを入力してください",
          email: "メールアドレスを正確に入力してください",
        }/*,
        chkemail: {
          required: "確認用のメールアドレスを入力してください",
          email: "確認用のメールアドレスを正確に入力してください",
          equalTo: "メールアドレスが一致しません",
        }*/,
        comment: {
          required: "お問い合わせ内容を入力してください",
        }
      },

      //エラーメッセージ出力箇所設定
      errorPlacement: function (error, element) {
        //ここにエラーメッセージの出力箇所を設定
        //ラジオボタン
        if (element.attr("name") === "radio") {
          error.insertAfter("#radioErr");
        //チェックボックス
        } else if (element.attr("name") === "checkbox[]") {
          error.insertAfter("#checkboxErr");
          //標準出力箇所（フォーム項目の後にエラーメッセージを出力）
        } else {
          error.insertAfter(element);
        }
      },

      //validateのチェックが通り、submitが行われるときに実行するハンドラ
      submitHandler: function (orgThis, form, ev) {
        grecaptcha.ready(function () {
          grecaptcha.execute('6LcCdgEqAAAAAJBnZcPwaMYvMRTEQO-Cbjt3wdTz', { action: 'submit' }).then(function (token) {
            const myform = document.querySelector('#recaptcha_form');
            const token_input = document.createElement('input');
            token_input.type = 'hidden';
            token_input.name = 'recaptcha_response';
            token_input.value = token;
            myform.appendChild(token_input);
            myform.submit();
          });
        });
      },
    });
  }
});